<template>
  <v-container>
    <v-dialog v-model="showpopup" max-width="330">
      <v-card v-if="displayPopUp('loading')">
        <v-card-title> Loading </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            loading
            disabled
            color="blue-grey"
            class="ma-2 white--text"
            fab
          >
            <v-icon dark> mdi-cloud-upload </v-icon>
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
      <v-card v-if="displayPopUp('noauth')">
        <v-card-title class="headline"> Need log in </v-card-title>
        <v-card-text class="my-2">
          <p class="my-1 text-justify">
            You will need to log in to perform this operation.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-row justify="center">
            <v-col cols="6" align="center">
              <v-btn
                depressed
                color="grey lighten-3"
                @click="
                  showpopup = false;
                  popupstate = 'none';
                "
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
      <v-card v-if="displayPopUp('errorunlock')">
        <v-card-title> Error </v-card-title>
        <v-card-text class="my-2">
          <p class="my-1 text-justify">
            An error occured. You might have already unlocked this song. Please
            allow for a couple of minutes for the website to update.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-row justify="center">
            <v-col cols="6" align="center">
              <v-btn
                depressed
                color="grey lighten-3"
                @click="showpopup = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
      <v-card v-if="displayPopUp('validationunlock')">
        <v-card-title class="headline"> Record unlocked </v-card-title>
        <v-card-text class="my-2 text-justify">
          The record has been unlocked! Please allow for a couple of minutes for
          the website to update. You will then be able to listen to the now
          public song attached on the record page.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="blue lighten-4" @click="showpopup = false">
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="displayPopUp('unlock')">
        <v-card-title class="headline"> Unlock song </v-card-title>
        <v-card-text class="my-2 text-justify">
          You are about to release this song. This action is irreversible. Once
          you accept it, the song will be forever public.
        </v-card-text>
        <v-card-actions>
          <v-btn depressed color="red lighten-4" @click="showpopup = false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn depressed color="blue lighten-4" @click="startUnlock()">
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row justify="center" class="mb-1">
      <v-col class="rounded" :class="fillcolor" cols="12" lg="4">
        <v-row justify="center" align="center">
          <v-col>
            <v-row class="px-3 pt-1 text-h6">
              <v-hover v-slot="{ hover }">
                <router-link
                  :to="recordLink"
                  :class="{ 'grey--text text--darken-2': hover }"
                  class="black--text text-decoration-none mx-0"
                >
                  {{ data.title }}
                </router-link>
              </v-hover>
            </v-row>
            <v-row class="px-3 pb-1 text-subtitle-1 font-weight-light">
              <v-hover v-slot="{ hover }">
                <router-link
                  :to="artistLink"
                  :class="{ 'text-decoration-none': !hover }"
                  class="black--text mx-0"
                >
                  {{ data.artist }}
                </router-link>
              </v-hover>
            </v-row>
          </v-col>
          <v-col>
            <v-row class="px-3 pt-1 text-h6" justify="end">
              <v-btn
                v-if="!isSongReleased"
                depressed
                class="amber lighten-3"
                @click="
                  showpopup = true;
                  popupstate = 'unlock';
                "
                >Unlock</v-btn
              >
              <v-btn v-if="isSongReleased" depressed disabled>Unlocked</v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  getRecordDetails,
  unlockRecord,
  getArtistSlug
} from "@/services/RecordService.js";
import { mapGetters } from "vuex";

export default {
  name: "RecordListing",
  props: ["id"],
  data: () => ({
    data: {},
    showpopup: false,
    popupstate: "none",
    loadingpopup: false,
    fillcolor: "amber lighten-4",
  }),
  created() {
    this.getRecordData();
  },
  computed: {
    ...mapGetters(["isUserAuth", "getProfile"]),
    isSongReleased: function () {
      return this.data.audiokey;
    },
    recordLink: function() {
      return "/record/" + this.id;
    },
    artistLink: function() {
      return "/artist/" + this.data.artistslug;
    }
  },
  methods: {
    async getRecordData() {
      let data = await getRecordDetails(this.id);
      let artistSlug = await getArtistSlug(data.artistID);
      data["artistslug"] = artistSlug;

      this.data = data;
    },
    displayPopUp(state) {
      if (!this.isUserAuth) return state === "noauth";
      else if (this.loadingpopup) return state === "loading";
      else if (state === "unlock" && this.isUserAuth)
        return this.popupstate === "unlock";
      else if (state === "validationunlock" && this.isUserAuth)
        return this.popupstate === "validationunlock";
      else if (state === "errorunlock")
        return this.popupstate === "errorunlock";
    },
    startUnlock() {
      this.loadingpopup = true;
      unlockRecord(this.id)
        .then(() => {
          this.popupstate = "validationunlock";
          this.loadingpopup = false;
        })
        .catch((error) => {
          console.log(error);
          this.popupstate = "errorunlock";
          this.loadingpopup = false;
        });
    },
  },
};
</script>

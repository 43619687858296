<template>
  <v-container>
    <v-card
      v-if="showForStatus(onboardingStatus.CHECKEMAIL)"
      class="mt-4 mx-auto"
      width="400px"
    >
      <v-card-text class="text-justify">
        <p class="my-2">
          If you have entered a correct email to log in, you should have
          received a connection link by now! It could be in your spam folder.
        </p>
        <p class="my-2">
          You can always try again by pressing "Log in / sign up" button in the
          menu.
        </p>
        <p class="my-2">
          Please open the link in the same browser as the one your connection
          request.
        </p>
      </v-card-text>
    </v-card>

    <v-card
      v-if="showForStatus(onboardingStatus.CREATEUSERNAME)"
      class="mt-4 mx-auto"
      width="300px"
    >
      <v-card-title> Create a username </v-card-title>
      <v-card-text class="mt-4 pr-5">
        <div
          v-if="usernameTaken"
          class="pa-2 mb-4 rounded red lighten-4 red--text"
        >
          Username already taken
        </div>
        <v-text-field
          v-model="username"
          placeholder="Username"
          hide-details="auto"
          dense
          outlined
          :rules="rules_username"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          depressed
          class="mb-3 mr-3"
          color="blue lighten-3"
          :disabled="!isValidUsername()"
          @click="createUsername"
        >
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card
      v-if="showForStatus(onboardingStatus.EXPLAINWALLET)"
      class="mt-4 mx-auto"
      width="400px"
    >
      <v-card-title> Connect to your wallet </v-card-title>
      <v-card-text class="mt-4 pr-5 text-justify">
        <p>
          Your account does not have an attached wallet yet. A wallet is
          required to buy and sell records on the marketplace. Continue to
          create or log in to a
          <a href="https://blocto.portto.io/">Blocto</a> wallet.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          depressed
          class="mb-3 mr-3"
          color="blue lighten-3"
          @click="connectWallet"
        >
          Connect / Create
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card
      v-if="showForStatus(onboardingStatus.ATTACHWALLET)"
      class="mt-4 mx-auto"
      width="400px"
    >
      <v-card-title> Link your wallet to your account </v-card-title>
      <v-card-text class="mt-4 pr-5 text-justify">
        <p>We now need to attach your wallet to your account.</p>
        <p>
          After clicking <b>Continue</b>, you will get prompted to link
          your account to your wallet.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          depressed
          class="mb-3 mr-3"
          color="blue lighten-3"
          @click="startAttachWallet"
        >
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card
      v-if="showForStatus(onboardingStatus.WALLETTAKEN)"
      class="mt-4 mx-auto"
      width="400px"
    >
      <v-card-title> Wallet already used </v-card-title>
      <v-card-text class="mt-4 pr-5 text-justify">
        <p>
          This wallet has already been used by another account, please try
          again.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          depressed
          class="mb-3 mr-3"
          color="blue lighten-3"
          @click="connectWallet"
        >
          Retry
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card
      v-if="showForStatus(onboardingStatus.WRONGWALLET)"
      class="mt-4 mx-auto"
      width="400px"
    >
      <v-card-title> Wrong wallet </v-card-title>
      <v-card-text class="mt-4 pr-5 text-justify">
        <p>
          Your linked wallet is not this one, please connect the associated one.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          depressed
          class="mb-3 mr-3"
          color="blue lighten-3"
          @click="connectWallet"
        >
          Retry
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card
      v-if="showForStatus(onboardingStatus.SETUPWALLET)"
      class="mt-4 mx-auto"
      width="400px"
    >
      <v-card-title> Prepare wallet </v-card-title>
      <v-card-text class="mt-4 pr-5 text-justify">
        <p>
          Your wallet is not fully set up. After clicking <b>Set up</b>, you
          will get prompted to set up your wallet.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          depressed
          class="mb-3 mr-3"
          color="blue lighten-3"
          @click="setupwallet"
        >
          Set up
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card
      v-if="showForStatus(onboardingStatus.CONNECTWALLET)"
      class="mt-4 mx-auto"
      width="400px"
    >
      <v-card-title> Connect to wallet </v-card-title>
      <v-card-text class="mt-4 pr-5 text-justify">
        <p>Please connect to your wallet.</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          depressed
          class="mb-3 mr-3"
          color="blue lighten-3"
          @click="connectWallet"
        >
          Connect
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card
      v-if="showForStatus(onboardingStatus.LOADING)"
      class="mt-4 mx-auto"
      width="400px"
    >
      <v-card-title> Loading </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn loading disabled color="blue-grey" class="ma-2 white--text" fab>
          <v-icon dark> mdi-cloud-upload </v-icon>
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>

    <v-card
      v-if="showForStatus(onboardingStatus.ERROR)"
      class="mt-4 mx-auto"
      width="400px"
    >
      <v-card-text class="mt-4 pr-5 text-justify">
        <p class="mb-0">An error occure, please reload this page.</p>
      </v-card-text>
    </v-card>
    <v-card
      v-if="showForStatus(onboardingStatus.CONNECTED)"
      class="mt-4 mx-auto"
      width="400px"
    >
      <v-card-text class="mt-4 pr-5 text-center">
        <p class="mb-0">
          You are now connected! Please read our FAQ to get familiar with the
          different functionalities.
        </p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { userStatus } from "@/store";
import { checkIfUsernameTaken } from "@/services/UserService";

export default {
  name: "Onboarding",
  components: {},
  data: () => ({
    username: null,
    usernameTaken: false,
    onboardingStatus: {
      CHECKEMAIL: "checkemail",
      ERROR: "error",
      LOADING: "loading",
      CREATEUSERNAME: "username",
      EXPLAINWALLET: "explainwallet",
      ATTACHWALLET: "attachwallet",
      WALLETTAKEN: "wallettaken",
      WRONGWALLET: "wrongwallet",
      SETUPWALLET: "setupwallet",
      CONNECTWALLET: "connectwallet",
      CONNECTED: "connected",
    },
    rules_username: [
      (value) => !!value || "Required.",
      (value) => (value || "").length <= 32 || "Max 32 characters",
      (value) => (value || "").length > 3 || "Min 4 characters",
      (value) => {
        const pattern = /^[0-9a-zA-Z_.-]+$/;
        return (
          pattern.test(value) ||
          "Invalid username. Letters, numbers or '-', '_', '.' only."
        );
      },
    ],
  }),
  computed: {
    ...mapGetters(["getSignInEmail", "getStatus", "getAddress"]),
  },
  methods: {
    ...mapActions([
      "sendAuthEmail",
      "signInWithLink",
      "changeUsername",
      "loginWallet",
      "logoutWallet",
      "attachWallet",
      "prepareWallet",
    ]),
    sendSignInLink() {
      console.info("NYI: check email format");
      this.sendAuthEmail(this.email);
    },
    showForStatus(status) {
      if (status == this.onboardingStatus.CHECKEMAIL)
        return this.getStatus == userStatus.NONE;
      if (status == this.onboardingStatus.ERROR)
        return this.getStatus == userStatus.ERROR;
      if (status == this.onboardingStatus.LOADING)
        return this.getStatus == userStatus.LOADING;
      else if (status == this.onboardingStatus.CREATEUSERNAME)
        return this.getStatus == userStatus.NOUSERNAME;
      else if (status == this.onboardingStatus.EXPLAINWALLET)
        return this.getStatus == userStatus.NOADDRESS;
      else if (status == this.onboardingStatus.ATTACHWALLET)
        return this.getStatus == userStatus.DETACHEDADDRESS;
      else if (status == this.onboardingStatus.WALLETTAKEN)
        return this.getStatus == userStatus.ADDRESSTAKEN;
      else if (status == this.onboardingStatus.WRONGWALLET)
        return this.getStatus == userStatus.WRONGADDRESS;
      else if (status == this.onboardingStatus.SETUPWALLET)
        return this.getStatus == userStatus.NOTSETUPWALLET;
      else if (status == this.onboardingStatus.CONNECTWALLET)
        return this.getStatus == userStatus.DISCONNECTEDWALLET;
      else if (status == this.onboardingStatus.CONNECTED)
        return this.getStatus == userStatus.CONNECTED;

      return false;
    },
    async createUsername() {
      if (this.isValidUsername()) {
        this.usernameTaken = false;
        let taken = await checkIfUsernameTaken(this.username);
        console.log(taken);
        if (!taken) this.changeUsername(this.username);
        else this.usernameTaken = true;
      }
    },
    isValidUsername() {
      const pattern = /^[0-9a-zA-Z_.-]+$/;
      return (
        !!this.username &&
        (this.username || "").length <= 32 &&
        (this.username || "").length > 3 &&
        pattern.test(this.username)
      );
    },
    async connectWallet() {
      await this.logoutWallet();
      this.loginWallet();
    },
    startAttachWallet() {
      this.attachWallet();
    },
    setupwallet() {
      this.prepareWallet();
    },
  },
  created() {
    const url = location.href;
    this.signInWithLink(url);
  },
};
</script>

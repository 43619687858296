<template>
  <v-container>
    <RecordListing v-if="recordstatus === 'none'" :id="id" />
    <AuctionListing v-if="recordstatus === 'auction'" :id="id" />
  </v-container>
</template>

<script>
import RecordListing from "./RecordListing"
import AuctionListing from "./AuctionListing"

export default {
  name: "GenericListing",
  props: ["id", "recordstatus"],
  data() {
    return {};
  },
  components: {
    RecordListing,
    AuctionListing
  },
  methods: {
    
  },
  
};
</script>
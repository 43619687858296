<template>
  <v-container>
    <v-row justify="center" class="mb-1">
      <v-col class="rounded" :class="fillcolor" cols="12" lg="4">
        <v-row justify="center" align="center">
          <v-col>
            <v-row class="px-3 pt-1 text-h6">
              <v-hover v-slot="{ hover }">
                <router-link
                  :to="recordLink"
                  :class="{ 'grey--text text--darken-2': hover }"
                  class="black--text text-decoration-none mx-0"
                >
                  {{ data.title }}
                </router-link>
              </v-hover>
            </v-row>
            <v-row class="px-3 pb-1 text-subtitle-1 font-weight-light">
              <v-hover v-slot="{ hover }">
                <router-link
                  :to="artistLink"
                  :class="{ 'text-decoration-none': !hover }"
                  class="black--text mx-0"
                >
                  {{ data.artist }}
                </router-link>
              </v-hover>
            </v-row>
          </v-col>
          <v-col>
            <v-row
              v-if="!!bidderName"
              class="px-3 pt-1 text-body"
              justify="end"
            >
              ₣USD {{ currentBid }}
            </v-row>
            <v-row
              v-if="!!bidderName"
              class="px-3 pb-1 text-caption"
              justify="end"
            >
              bid by
              <v-hover v-slot="{ hover }">
                <router-link
                  :to="bidderLink"
                  :class="{ 'text-decoration-none': !hover }"
                  class="black--text pl-1"
                >
                  @{{ bidderName }}
                </router-link>
              </v-hover>
            </v-row>
            <v-row
              v-if="!bidderName"
              class="px-3 pt-1 text-caption"
              justify="end"
            >
              No offer
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getRecordDetails, getArtistSlug } from "@/services/RecordService";
import { getAuctionDetails } from "@/services/AuctionService.js";
import { mapGetters } from "vuex";

export default {
  name: "AuctionListing",
  props: ["id"],
  data: () => ({
    data: {},
    fillcolor: "amber lighten-4",
  }),
  created() {
    this.getRecordData();
  },
  computed: {
    ...mapGetters(["isUserAuth", "getProfile"]),
    isSongReleased: function () {
      return this.data.url !== "unreleased";
    },
    bidderName: function() {
      return this.data.leader;
    },
    currentBid: function() {
      if (!this.bidderName && this.data.nextMinBid)
        return parseFloat(this.data.nextMinBid).toFixed(2);
      else if (!this.bidderName) return "-";
      else return parseFloat(this.data.currentBid).toFixed(2);
    },
    recordLink: function() {
      return "/record/" + this.data.recordID;
    },
    artistLink: function() {
      return "/artist/" + this.data.artistslug;
    },
    bidderLink: function() {
      if (!this.bidderName) return "#";
      else return "/profile/" + this.bidderName;
    },
  },
  methods: {
    async getRecordData() {
      let data = await getAuctionDetails(this.id);
      let record = await getRecordDetails(data.recordID);
      delete record.owner;

      data = Object.assign({}, data, record);

      let artistSlug = await getArtistSlug(data.artistID)
      data["artistslug"] = artistSlug

      this.data = data
    },
  },
};
</script>

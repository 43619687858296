<template>
  <v-container class="mt-4">
    <v-row justify="center">
      <p class="text-h4">Account</p>
    </v-row>
    <v-row justify="center" align="center">
      <v-hover v-slot="{ hover }">
        <router-link
          :to="'profile/' + getProfile.username"
          :class="{ 'text-decoration-none': !hover }"
          class="mt-0 black--text text-caption pl-0"
        >
          Check profile
        </router-link>
      </v-hover>
    </v-row>
    <v-row v-if="!$vuetify.breakpoint.smAndDown" justify="center" class="mb-0 grey--text">
      <v-col cols="6" md="4" class="text-right pb-0"> username </v-col>
      <v-col cols="6" md="4" class="pb-0"> wallet address </v-col>
    </v-row>
   <v-row v-if="!$vuetify.breakpoint.smAndDown" justify="center" class="mt-0" align="center">
      <v-col cols="6" md="4" class="text-right py-1" justify="center">
        <p class="text-h6 mb-0">@{{ getProfile.username }}</p>
      </v-col>
      <v-col cols="6" md="4" justify="center" class="py-1">
        <p class="text-h6 mb-0 font-weight-light">{{ getAddress }}</p>
      </v-col>
    </v-row>

    <v-row v-if="$vuetify.breakpoint.smAndDown" justify="center" class="mb-0 grey--text">
      <span class="text-right"> username </span>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.smAndDown" justify="center" class="mt-0 mb-3 black--text">
      <span class="text-h6"> @{{ getProfile.username }} </span>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.smAndDown" justify="center" class="mb-0 grey--text">
      <span class="text-right"> wallet address </span>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.smAndDown" justify="center" class="mt-0 black--text">
      <span class="text-h6"> {{ getAddress }} </span>
    </v-row>
    
    

    <v-row justify="center">
      <v-col cols="10" lg="4" class="text-right pb-0 mb-3">
        <v-divider />
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" md="4" justify="center" class="py-0">
        <p class="text-h6 my-0" :class="{'text-center': $vuetify.breakpoint.smAndDown, 'text-right': !$vuetify.breakpoint.smAndDown}">Credit available</p>
      </v-col>
      <v-col cols="12" md="4" justify="center" class="py-0">
        <p class="text-h6 font-weight-light mb-0 mt-0" :class="{'text-center': $vuetify.breakpoint.smAndDown, 'text-left': !$vuetify.breakpoint.smAndDown}">
          ₣USD {{ getBalanceString() }}
        </p>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" class="text-caption mt-0 pt-0">
      <v-col cols="12" md="4" offset="0" offset-md="4" justify="center" class="mt-3 pt-0">
        <p class="text-subtitle-2 mt-0 font-weight-light" :class="{'text-center': $vuetify.breakpoint.smAndDown, 'text-left': !$vuetify.breakpoint.smAndDown}">
          (1 ₣USD = $1)
        </p></v-col
      >
    </v-row>
    <v-row justify="center" align="center">
      <v-btn
        depressed
        color="rounded-pill blue lighten-4"
        href="https://swap-testnet.blocto.app/#/swap"
      >
        Get ₣USD
      </v-btn>
    </v-row>
    <v-row justify="center" align="center">
      <v-hover v-slot="{ hover }">
        <router-link
          to="/faq"
          :class="{ 'text-decoration-none': !hover }"
          class="mt-1 black--text text-caption pl-0"
        >
          What is ₣USD
        </router-link>
      </v-hover>
    </v-row>
    <v-row justify="center">
      <v-col cols="10" lg="4" class="text-right pb-0">
        <v-divider />
      </v-col>
    </v-row>
    <v-row justify="center">
      <p class="text-h5 mt-3">Records owned</p>
    </v-row>
    <v-row justify="center" v-for="record in records" :key="record.id">
      <GenericListing :id="record.id" :recordstatus="record.status" />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getWalletBalance } from "@/services/WalletService";
import { getRecordsUser } from "@/services/RecordService";

import GenericListing from "@/components/GenericListing";

export default {
  name: "Account",
  computed: {
    ...mapGetters(["getProfile", "getStatus", "getAddress"]),
  },
  components: {
    GenericListing,
  },
  data: () => ({
    showNameUpdate: false,
    newName: null,
    balance: null,
    loading: false,
    records: [],
  }),
  created() {
    this.getBalance();
    this.loadRecords();
  },
  methods: {
    ...mapActions(["login", "logout", "setProfileName"]),
    async getBalance() {
      this.balance = parseFloat(await getWalletBalance(this.getAddress));
    },
    async loadRecords() {
      let { records } = await getRecordsUser(this.getProfile.username);
      this.records = records;
    },
    getBalanceString() {
      if (this.balance) return this.balance.toFixed(2);
      else return "-";
    },
  },
};
</script>
